import { IconCalendar, IconChecklist, IconChevronLeft, IconChevronRight, IconDisabled, IconEdit, IconSearch } from '@tabler/icons-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useNavigate } from 'react-router-dom';

import { IconReload } from '@tabler/icons-react';
import useKarat from '../features/useKarat';
import Input from '../../components/Input';
import { getAuthorizationHeader, http } from '../../config/http';
import { DinamicTitle } from '../../utils/DinamicTitle';
import Layouts from '../../layouts/Layouts';
import DetailBarangPenjualan from '../Transaksi/DetailBarangPenjualan';
import ModalTransaksiTertunda from './ModalTransaksiTertunda';
import { toast } from 'react-toastify';
import ModalProsesTransaksiTertunda from './ModalProsesTransaksiTertunda';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Tooltip } from 'antd';
import { IconEye } from '@tabler/icons-react';
import { IconTrash } from '@tabler/icons-react';
import ModalKonfirmasiHapusTransaksi from './ModalKonfirmasiHapusTransaksi';

const ListTransaksiTertunda = () => {
  const navigate = useNavigate();
  const { karatOption, karatOptionId, setKaratOptionId } = useKarat();
  const [trxPenjualan, setTrxPenjualan] = useState([]);
  // console.log(trxPenjualan)
  const [openLoading, setOpenLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectCabang, setSelectCabang] = useState([]);
  const [idCabang, setIdCabang] = useState(0);
  const [idKategori, setIdKategori] = useState(0);
  const [kategori, setKategori] = useState([]);

  const [openCalender, setOpenCalender] = useState(false);
  const [filterDate, setFilterDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const awal = moment(startDate).format('DD-MM-YYYY');
  const akhir = moment(endDate).format('DD-MM-YYYY');

  // const [totalHargaPenjualan, setTotalHargaPenjualan] = useState(0);
  // const [totalHargaFilter, setTotalHargaFilter] = useState(0);

  const [filterBerat, setFilterBerat] = useState('');
  const [berat, setBerat] = useState('');

  const filterBeratBarang = () => {
    setBerat(filterBerat);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [search, setSearch] = useState('');

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const [itemsPerPage, setItemsPerPage] = useState('');
  const startIndex = (currentPage - 1) * itemsPerPage;
  const [isProses, setIsProses] = useState(0);

  async function getTrxTertunda() {
    setLoading(true);
    await http
      .get(
        `/transaksi_tertunda${search === '' ? `?page=${currentPage}` : `?faktur=${search}`}${(idCabang === 0 || idCabang === '') ? `` : `&cabang=${idCabang}`}
        ${(idKategori === 0 || idKategori === '') ? `` : `&kategori=${idKategori}`}${startDate === null && endDate === null ? `` : `&tgl_awal=${awal}&tgl_akhir=${akhir}`
        }${karatOptionId === '' ? '' : `&karat=${karatOptionId}`}${berat === '' ? '' : `&berat=${berat}`}${isProses === '' ? '&status=0' : `&status=${isProses}`}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        },
      )
      .then((res) => {
        setTrxPenjualan(res.data.data.data);
        setCurrentPage(res.data.data.current_page);
        setItemsPerPage(res.data.data.per_page);
        setLastPage(res.data.data.last_page);
        return res.data.data;
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setLoading(false);
  }
  useEffect(() => {
    getTrxTertunda();
  }, [
    idCabang,
    idKategori,
    search.length >= 12 ? search : '',
    awal,
    akhir,
    startDate,
    endDate,
    currentPage,
    navigate,
    karatOptionId,
    berat,
    isProses,
  ]);

  const [isOpenDetailTransaksiTertunda, setIsOpenDetailTransaksiTertunda] = useState(false);
  let [isOpenLoadingDetail, setIsOpenLoadingDetail] = useState(false);
  const [detailItemTransaksiTertunda, setDetailTransaksiTertunda] = useState(null);
  function closeDetailTransaksiTertunda() {
    setIsOpenDetailTransaksiTertunda(false);
  }
  function openDetailTransaksiTertunda() {
    setIsOpenDetailTransaksiTertunda(true);
  }
  async function getDetailTransaksiTertunda(id) {
    setIsOpenLoadingDetail(true);
    await http
      .get(`/transaksi_tertunda/${id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setDetailTransaksiTertunda(res.data.data);
        openDetailTransaksiTertunda();
      });
    setIsOpenLoadingDetail(false);
  }

  const [loadingHapus, setLoadingHapus] = useState(false);
  const [isOpenModalTTH, setIsOpenModalTTH] = useState(false);
  const [idTerhapus, setIdTerhapus] = useState('');

  function closeModalTTH() {
    setIsOpenModalTTH(false);
  }
  function openModalTTH(id) {
    setIsOpenModalTTH(true);
    setIdTerhapus(id)
  }


  async function handleHapusTransaksiTertunda() {
    setLoadingHapus(true);
    await http.delete(`/transaksi_tertunda/${idTerhapus}`).then((res) => {
      toast.success('Data berhasil dihapus', { autoClose: 600, });
      closeModalTTH();
      getTrxTertunda();
      return res.data;
    }).catch((err) => {
      console.log(err)
      toast.warning('Terjadi kesalahan di server', { autoClose: 600, });
    });
    setLoadingHapus(false);
  }


  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    async function getCabangSelect() {
      await http
        .get(`cabang?is_toko=1`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setSelectCabang(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getCabangSelect();
  }, [navigate]);

  useEffect(() => {
    async function getKategori() {
      await http
        .get('/kategori', {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setKategori(res.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getKategori();
  }, []);

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  };

  function handleSelect(date) {
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
  }

  function checkBerat(number) {
    const beratConfirm = parseFloat(number);
    return beratConfirm % 1 === 0
      ? beratConfirm.toString()
      : beratConfirm
        .toFixed(2)
        .replace(/\.?0+$/, '')
        .replace('.', ',');
  }
  DinamicTitle('List Transaksi Penggarus || Komaladewi');
  const [isOpenModalTransaksiTertunda, setIsOpenModalTransaksiTertunda] = useState(false);
  const [idTransaksiTertunda, setIdTransaksiTertunda] = useState('');
  function openModalTransaksiTertunda(id) {
    setIsOpenModalTransaksiTertunda(true);
    setIdTransaksiTertunda(id);
  }
  function closeModaTransaksiTertunda() {
    setIsOpenModalTransaksiTertunda(false);
  }
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
      setSelectAll(false);
    } else {
      const allIds = trxPenjualan.map((bt) => bt.item.id);
      setSelectedIds(allIds);
      setSelectAll(true);
    }
  };
  const handleSingleSelect = (id) => {
    const isSelected = selectedIds.includes(id);
    if (isSelected) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };
  const [isOpenProses, setIsOpenProsess] = useState(false);
  function closeModalProses() {
    setIsOpenProsess(false)
  }
  function reloadData() {
    getTrxTertunda();
  }
  function handleOpenModalProses() {
    if (selectedIds.length !== 0) {
      setIsOpenProsess(true)
    } else {
      toast.error('Pastikan telah memilih data dulu', { autoClose: 600, })
    }
  }
  return (
    <Layouts
      title={'List Transaksi Penggarus'}
      compFilter={
        <div className="flex items-center gap-0.5">
          <ModalTransaksiTertunda
            getTrxTertunda={getTrxTertunda}
            isOpenModalTransaksiTertunda={isOpenModalTransaksiTertunda}
            closeModaTransaksiTertunda={closeModaTransaksiTertunda}
            setOpenLoading={setOpenLoading}
            idTransaksiTertunda={idTransaksiTertunda}
          />
          <button onClick={handleOpenModalProses} disabled={isOpenProses} className='p-2 bg-green-500 hover:bg-green-600 text-white'>
            Proses Penggarus
          </button>
          <div className="dropdown dropdown-bottom">
            <div
              tabIndex={0}
              onClick={() => setOpenCalender(!openCalender)}
              className="bg-orange-500 text-white p-1  cursor-pointer"
            >
              <IconCalendar className="text-white" />
            </div>
            <div tabIndex={0} className={`dropdown-content -right-40 menu p-2 shadow bg-base-100`}>
              <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
            </div>
          </div>

        </div>
      }
    >
      {isOpenLoadingDetail && <LoadingIndicator />}
      <ModalProsesTransaksiTertunda
        closeModalRestore={closeModalProses}
        isOpenModalRestore={isOpenProses}
        reloadData={reloadData}
        selectedIds={selectedIds}
      />
      <div className=" ">
        <div className="flex items-center justify-between scrollbar-none overflow-x-auto ">
          <div className="flex items-center justify-center md:justify-start w-full gap-0.5">
            <div>
              <div className="flex  gap-0.5">
                <select
                  name=""
                  id=""
                  onChange={(e) => setIdCabang(e.target.value)}
                  className=" p-2  cursor-pointer "
                >
                  <option value=""> ETALASE</option>
                  {selectCabang.map((cb) => (
                    <option value={cb.id} key={cb.id}>
                      {cb.nama_cabang}
                    </option>
                  ))}
                </select>
                <select
                  name=""
                  id=""
                  onChange={(e) => setIdKategori(e.target.value)}
                  className=" p-2  cursor-pointer"
                >
                  <option value=""> KATEGORI</option>
                  {kategori.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.nama_kategori}
                    </option>
                  ))}
                </select>
                <select
                  name=""
                  id=""
                  onChange={(e) => setKaratOptionId(e.target.value)}
                  className="p-2   cursor-pointer "
                >
                  <option value=""> KARAT</option>
                  {karatOption.map((kto) => (
                    <option key={kto} value={kto}>
                      {kto}
                    </option>
                  ))}
                </select>
                <select
                  name=""
                  id=""
                  onChange={(e) => setIsProses(e.target.value)}
                  className="p-2 cursor-pointer "
                >
                  <option value="">STATUS</option>
                  <option value="1">Telah diproses</option>
                  <option value="0">Belum diproses</option>
                </select>
                <div className="max-w-lg flex w-52 gap-0.5 items-center">
                  <Input onChange={(e) => setFilterBerat(e.target.value)} placeholder="Cari Berat" />
                  <button onClick={filterBeratBarang} className="bg-teal-500 px-1.5 h-[2.30rem] text-white ">
                    <IconSearch />
                  </button>
                  <button
                    onClick={() => window.location.reload()}
                    className="bg-gray-800 text-white px-1.5 h-[2.30rem] "
                  >
                    <IconReload />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t">
          <ModalKonfirmasiHapusTransaksi
            submitProses={handleHapusTransaksiTertunda}
            isOpenModalProses={isOpenModalTTH}
            closeModaProses={closeModalTTH}
            isLoadingSubmit={loadingHapus}
          />
          <DetailBarangPenjualan
            isTertunda
            label={'Transaksi Penggarus'}
            checkBerat={checkBerat}
            closeModal={closeDetailTransaksiTertunda}
            detailPenjualan={detailItemTransaksiTertunda}
            isOpen={isOpenDetailTransaksiTertunda}
          />
          {openLoading && (
            <LoadingIndicator />
          )}
          <div className="">
            <div className="inline-block min-w-full overflow-hidden">
              <div className="relative overflow-x-auto shadow-md  overflow-hidden scrollbar-thin">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-2 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-[13px] font-semibold text-gray-700 uppercase tracking-wider">
                        No
                      </th>

                      <th className="px-2 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-[13px] font-semibold text-gray-700 uppercase tracking-wider">
                        Kode Barang
                      </th>

                      <th className="px-2 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-[13px] font-semibold text-gray-700 uppercase tracking-wider">
                        Nama Barang
                      </th>

                      <th className="px-2 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-[13px] font-semibold text-gray-700 uppercase tracking-wider">
                        Berat | Karat
                      </th>
                      <th className="px-2 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-[13px] font-semibold text-gray-700 uppercase tracking-wider">
                        Harga
                      </th>
                      <th className="px-2 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-[13px] font-semibold text-gray-700 uppercase tracking-wider">
                        Rata | Gram
                      </th>
                      <th className="px-2 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-[13px] font-semibold text-gray-700 uppercase tracking-wider">
                        Kasir
                      </th>
                      <th className="px-2 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-[13px] font-semibold text-gray-700 uppercase tracking-wider">
                        Tanggal
                      </th>
                      <th className="px-2 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-[13px] font-semibold text-gray-700 uppercase tracking-wider">
                        Aksi
                      </th>
                      <th className="px-2 py-2 border-b-2 border-r border-gray-200 bg-gray-100 text-left text-[13px] font-semibold text-gray-700 uppercase tracking-wider">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          className='cursor-pointer checkbox checkbox-primary w-4 h-4 border-slate-700 rounded'
                          onChange={handleSelectAll}
                          name=""
                          id=""
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <>
                        <tr className="flex  items-center justify-center">
                          <td className="w-5 h-5  rounded-full animate-spin border-4 border-dashed border-slate-800 border-t-transparent"></td>
                          <td className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md">Memuat data....</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {trxPenjualan.map((trx, i) => {
                          return (
                            <tr
                              key={trx.id}
                              onClick={(e) => {
                                e.stopPropagation();
                                getDetailTransaksiTertunda(trx.id);
                              }}
                              className="hover:bg-gray-100 bg-white cursor-pointer"
                            >
                              <td className="px-2 py-0 border-b border-r border-gray-200  text-[13px]">
                                <div className="ml-3">
                                  <p className="text-gray-900 whitespace-no-wrap">{startIndex + i + 1}.</p>
                                </div>
                              </td>
                              <td className="px-2 py-0 whitespace-nowrap border-r border-b border-gray-200  text-[13px]">
                                <p className="text-gray-900 whitespace-no-wrap">{trx.item.kode_item}</p>
                              </td>
                              <td className="px-2 py-0 whitespace-nowrap border-r border-b border-gray-200  text-[13px]">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {trx.item?.jenis_kategori?.nama_jenis}{' '}
                                  {trx.is_refund == false ? (
                                    ``
                                  ) : (
                                    <span className="text-red-600 text-[13px] font-light">dikembalikan</span>
                                  )}{' '}
                                </p>
                              </td>

                              <td className="px-2 py-0 whitespace-nowrap border-r border-b border-gray-200  text-[13px]">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {trx.item?.berat} gr | {trx.item?.karat} krt
                                </p>
                              </td>
                              <td className="px-2 py-0 border-r whitespace-nowrap border-b border-gray-200  text-[13px]">
                                Rp {numberWithCommas(trx.total_harga == undefined ? '0' : trx.total_harga)}
                              </td>
                              <td className="px-2 py-0  border-r whitespace-nowrap border-b border-gray-200  text-[13px]">
                                Rp{' '}
                                {numberWithCommas(
                                  trx.total_harga == undefined ? '0' : Math.round(trx.total_harga / trx.item?.berat),
                                )}{' '}
                                / gr
                              </td>
                              <td className="px-2 py-0 border-r whitespace-nowrap border-b border-gray-200  text-[13px]">
                                <p className="text-gray-900 whitespace-no-wrap">{trx.user?.name}</p>
                              </td>
                              <td className="px-2 py-0 border-r whitespace-nowrap border-b border-gray-200  text-[13px]">
                                <p className="text-gray-900 whitespace-no-wrap">{trx.created_at}</p>
                              </td>
                              <td className="px-2 py-0 border-r whitespace-nowrap border-b  border-gray-200  text-[13px]">
                                <span className='flex items-center gap-0.5'>
                                  <IconEye onClick={(e) => {
                                    e.stopPropagation();
                                    getDetailTransaksiTertunda(trx.id)
                                  }} className='bg-green-500 p-1 text-white cursor-pointer' />
                                  {!trx.is_proses ? (
                                    <IconTrash onClick={(e) => {
                                      e.stopPropagation();
                                      openModalTTH(trx.id);
                                    }} className='bg-red-500 p-1 text-white cursor-pointer' />
                                  ) : (
                                    <IconTrash onClick={(e) => {
                                      e.stopPropagation();
                                    }} className='bg-red-500 p-1 text-white cursor-pointer' />
                                  )}
                                </span>
                              </td>
                              {!trx.is_proses ? (
                                <td className="py-2 px-3  border-b border-r border-gray-200">
                                  <input
                                    checked={selectedIds.includes(trx.item.id)}
                                    onChange={() => handleSingleSelect(trx.item.id)}
                                    className='cursor-pointer checkbox checkbox-primary w-4 h-4 border-slate-700 rounded'
                                    type="checkbox"
                                    onClick={(e)=>e.stopPropagation()}
                                    name=""
                                    id=""
                                  />
                                </td>
                              ) : (
                                <td className="py-2 px-3  border-b border-r border-gray-200 text-sm text-green-500">
                                  selesai
                                </td>
                              )}
                              {/* <td className="px-[6px] py-1 whitespace-nowrap font-normal">
                                <input
                                  checked={selectedItems.includes(item)}
                                  onChange={(event) => handleCheckboxChange(event, item)}
                                  type="checkbox"
                                  checked={isCheckedItems[index]}
                                  onChange={handleCheck}
                                  data-index={index}
                                  className="cursor-pointer checkbox checkbox-primary w-4 h-4 border-slate-700 rounded"
                                />
                              </td> */}
                              {/* <td className="px-2 py-0 whitespace-nowrap border-b border-gray-200  text-[13px]">
                                <p>{trx.is_refund ? (
                                    <Tooltip title="Refuned">
                                    <div className='bg-red-700 w-4 h-4 rounded-full'/>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="not Refund">
                                    <div className='bg-green-700 w-4 h-4 rounded-full'/>
                                  </Tooltip>
                                )}</p>
                              </td> */}
                            </tr>
                          )
                        })}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white bottom-0 flex justify-between left-0 py-4 w-full absolute">
        <div className="flex justify-end w-full mx-5">
          <div className="items-center flex justify-center space-y-2 text-xs sm:space-y-0 sm:space-x-3 sm:flex">
            <span className="block">
              Page {currentPage} of {lastPage}{' '}
            </span>
            <div className="space-x-1">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                title="previous"
                type="button"
                className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
              >
                <IconChevronLeft />
              </button>
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                title="next"
                type="button"
                className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
              >
                <IconChevronRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default ListTransaksiTertunda;
